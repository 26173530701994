import * as React from "react";
import {
  Page,
  Card,
  Button,
  Layout,
  FormLayout,
  TextField,
  Select,
  Stack,
  RadioButton,
  Banner,
  Tag,
  TextStyle,
  EmptyState,
  Action,
} from "@shopify/polaris";
import { TitleBar, useAppBridge } from "@shopify/app-bridge-react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Redirect,
  ContextualSaveBar,
  Loading,
  Error,
  Toast,
} from "@shopify/app-bridge/actions";
import useQueryParam from "../../lib/useQueryParam";
import {
  customerTagsAndDescriptions,
  potentialCustomerTags,
} from "../../lib/customerTags";
// import { useRoutePropagation } from "@shopify/app-bridge-react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { navigate, PageProps } from "gatsby";
import { CircleAlertMajor } from "@shopify/polaris-icons";
// import { navigate } from "@reach/router"
interface CustomerData {
  customer: {
    displayName: string;
    id: string;
    tags: string[];
  };
}

interface CustomerVars {
  id: string;
}

interface TestCustomerData {
  id: string;
  tags: string[];
}

interface TestCustomerInput {
  id: string;
}
const GET_CUSTOMER_INFORMATION = gql`
  query dakinePriceInfo($id: ID!) {
    customer(id: $id) {
      displayName
      id
      tags
    }
  }
`;
const UPDATE_CUSTOMER = gql`
  mutation customerUpdate($input: CustomerInput!) {
    customerUpdate(input: $input) {
      customer {
        id
        tags
      }
      userErrors {
        field
        message
      }
    }
  }
`;
const CustomerSettings: React.FC<
  PageProps<{
    shop: string;
    host: string;
  }>
> = ({ location, data: pageData, pageContext }) => {
  // useRoutePropagation(location);
  const pageTitle = "Customer settings";
  // Maybe load from config later?
  const emptyPriceTagValue = "none";

  const selfServeTagValue = "CML_INSTANT";
  const termsTagValue = "PAYLATER";
  const app = useAppBridge();
  // const [id] = useQueryParam("id", null);
  const id = new URLSearchParams(location.search).get("id");

  const redirect = Redirect.create(app);
  const saveBarOptions = {
    saveAction: {
      disabled: false,
      loading: false,
    },
    discardAction: {
      disabled: false,
      loading: false,
      discardConfirmationModal: true,
    },
  };
  const contextualSaveBar = ContextualSaveBar.create(app, saveBarOptions);

  const [saveSubscription, setSaveSubscription] = useState<Action>(null);

  const [hasUnappliedChange, setHasUnappliedChange] = useState(false);
  // const [getCustomerData, { loading, error, data }] = useLazyQuery<
  //   CustomerData,
  //   CustomerVars
  // >(GET_CUSTOMER_INFORMATION);

  // useEffect(() => {
  //   if (id) {
  //     getCustomerData({
  //       variables: {
  //         id: `gid://shopify/Customer/${id}`,
  //       },
  //     });
  //   }
  // }, [id]);
  const { loading, error, data } = useQuery<CustomerData, CustomerVars>(
    GET_CUSTOMER_INFORMATION,
    {
      variables: {
        id: `gid://shopify/Customer/${id}`,
      },
    }
  );
  const [selectedPriceTag, setSelectedPriceTag] = useState(emptyPriceTagValue);

  const [customersSavedTags, setCustomersSavedTags] = useState<string[]>([]);
  const [customersUnsavedTags, setCustomersUnsavedTags] = useState<string[]>(
    []
  );
  const unsavedTagsRef = useRef<string[]>();
  unsavedTagsRef.current = customersUnsavedTags;

  const [saveCustomerRecord, { error: saveError, data: saveData }] =
    useMutation<
      { customerUpdate: TestCustomerInput },
      { input: TestCustomerData }
    >(UPDATE_CUSTOMER, {
      onCompleted: (result) => {
        // console.log("Mutation result", { result });
      },
      onError: (err) => {
        const { message } = err;
        const toastOptions = {
          message,
          duration: 5000,
          isError: true,
        };
        const toastError = Toast.create(app, toastOptions);
        toastError.dispatch(Toast.Action.SHOW);
      },
    });

  const [canSelectTermOptions, setCanSelectTermOptions] = useState(false);

  const [canSelectSelfServeOptions, setCanSelectSelfServeOptions] =
    useState(false);

  const [canPlaceOwnDiscountedOrders, setCanPlaceOwnDiscountedOrders] =
    useState(false);

  const [canPayLayer, setCanPayLater] = useState(false);

  const [priceOptions] = useState(() => {
    const options = customerTagsAndDescriptions().map(
      ({ tag, description }) => ({
        label: description,
        value: tag,
      })
    );
    options.sort((a, b) => a.label.localeCompare(b.label));
    options.unshift({
      label: "None",
      value: emptyPriceTagValue,
    });

    return options;
  });

  const updateCustomer = useCallback(
    () => {
      // console.log("Update customers tags", customersUnsavedTags);
      const tags = unsavedTagsRef.current;
      // console.log("Update customers tags", customersUnsavedTags);
      contextualSaveBar.set({
        saveAction: {
          loading: true,
          disabled: true,
        },
        discardAction: {
          disabled: true,
        },
      });
      saveCustomerRecord({
        variables: {
          input: {
            // id,
            id: `gid://shopify/Customer/${id}`,
            // tags: customersUnsavedTags,
            tags,
          },
        },
      })
        .then((update) => {
          // console.log({ update });
          // const { errors } = updated;
          // if (errors) {
          //   return;
          // }
          //setCustomersSavedTags(customersUnsavedTags);
          // setCustomersSavedTags(customersUnsavedTags);
          setCustomersSavedTags(tags);

          const toastSuccess = Toast.create(app, {
            message: "Customer updated",
            duration: 5000,
          });

          toastSuccess.dispatch(Toast.Action.SHOW);
        })
        .catch((err) => {
          const toastError = Toast.create(app, {
            message: "Error updating",
            duration: 5000,
            isError: true,
          });

          toastError.dispatch(Toast.Action.SHOW);
        })
        .finally(() => {
          contextualSaveBar.set({
            saveAction: {
              loading: false,
              disabled: false,
            },
            discardAction: {
              disabled: false,
            },
          });
        });
    },
    // [contextualSaveBar, customersUnsavedTags]
    [customersUnsavedTags]
  );

  const handlePriceLevelSelectChange = useCallback(
    (value) => {
      // removeUnsavedTag(selectedPriceTag);
      // addUnsavedTag(value);
      const set = new Set([...customersUnsavedTags]);
      // Delete can be ran multiple times
      set.delete(selectedPriceTag);
      if (value !== emptyPriceTagValue) {
        set.add(value);
      }
      // console.log({ set: [...set] });
      setCustomersUnsavedTags([...set]);
      setSelectedPriceTag(value);
    },
    [customersUnsavedTags, selectedPriceTag]
  );

  const handleSelfServeChange = useCallback(
    (_checked, newValue) => {
      // console.log({ _checked, newValue });
      const set = new Set([...customersUnsavedTags]);
      if (newValue === "self-serve-enabled") {
        setCanPlaceOwnDiscountedOrders(true);
        set.add(selfServeTagValue);
      } else {
        setCanPlaceOwnDiscountedOrders(false);
        set.delete(selfServeTagValue);
      }
      // console.log({ set: [...set] });
      setCustomersUnsavedTags([...set]);
    },
    [customersUnsavedTags, selfServeTagValue]
  );

  const handlePaymentTermsChange = useCallback(
    (_checked, newValue) => {
      // console.log({ _checked, newValue });
      // return setCanPayLater(newValue === "terms-enabled");
      const set = new Set([...customersUnsavedTags]);
      if (newValue === "terms-enabled") {
        setCanPayLater(true);
        set.add(termsTagValue);
      } else {
        setCanPayLater(false);
        set.delete(termsTagValue);
      }
      setCustomersUnsavedTags([...set]);
    },
    [customersUnsavedTags, termsTagValue]
  );

  const redirectToCustomer = useCallback(() => {
    return redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
      name: Redirect.ResourceType.Customer,
      resource: {
        id,
      },
    });
  }, [id]);

  const resetSelectedPriceLevel = useCallback(() => {
    const possibles = potentialCustomerTags().map((value) => value);
    const matching = possibles.find((v) => customersSavedTags.includes(v));
    const value = matching || emptyPriceTagValue;
    setSelectedPriceTag(value);
  }, [customersSavedTags]);

  useEffect(() => {
    const hasAPriceTag = selectedPriceTag !== emptyPriceTagValue;
    if (hasAPriceTag) {
      setCanSelectSelfServeOptions(true);
    } else {
      setCanSelectSelfServeOptions(false);
      setCanPlaceOwnDiscountedOrders(false);
    }
  }, [selectedPriceTag]);

  useEffect(() => {
    if (canPlaceOwnDiscountedOrders) {
      // setCanSelectSelfServeOptions(true);
      setCanSelectTermOptions(true);
    } else {
      setCanSelectTermOptions(false);
      // setCanSelectSelfServeOptions(false);
    }
  }, [canPlaceOwnDiscountedOrders]);

  useEffect(() => {
    if (error) {
      console.log({ error });
      const { result } = error.networkError as any;
      if (result) {
        const { code, url } = result;
        if (code === "need-install" && url) {
          const state = { url: url.toString() };
          console.log("Redirecting to install", { state });
          navigate(`/install/`, { state });
        }
      }
    }
  }, [error]);

  useEffect(() => {
    if (data?.customer?.tags) {
      // Set saved tags for later comparison
      setCustomersSavedTags(data.customer.tags);
      // Set unsaved tags for initial state changes
      setCustomersUnsavedTags(data.customer.tags);
    }
  }, [data]);

  useEffect(() => {
    // it works...
    const same =
      JSON.stringify(customersSavedTags.sort()) ==
      JSON.stringify(customersUnsavedTags.sort());
    setHasUnappliedChange(!same);
  }, [customersSavedTags, customersUnsavedTags]);

  useEffect(() => {
    // console.log(
    //   JSON.stringify({ customersSavedTags, termsTagValue, selfServeTagValue })
    // );
    const possiblePriceTags = potentialCustomerTags().map((value) => value);
    const matchingPriceTag = possiblePriceTags.find((v) =>
      customersSavedTags.includes(v)
    );
    const priceTagValue = matchingPriceTag || emptyPriceTagValue;
    setSelectedPriceTag(priceTagValue);
    setCanPlaceOwnDiscountedOrders(
      customersSavedTags.includes(selfServeTagValue)
    );
    setCanPayLater(customersSavedTags.includes(termsTagValue));

    const hasAPriceTag = priceTagValue !== emptyPriceTagValue;
    if (hasAPriceTag) {
      setCanSelectSelfServeOptions(true);
    } else {
      setCanSelectSelfServeOptions(false);
    }
  }, [customersSavedTags]);

  useEffect(() => {
    if (hasUnappliedChange) {
      contextualSaveBar.dispatch(ContextualSaveBar.Action.SHOW);
    } else {
      contextualSaveBar.dispatch(ContextualSaveBar.Action.HIDE);
    }
  }, [hasUnappliedChange]);

  contextualSaveBar.subscribe(ContextualSaveBar.Action.DISCARD, () => {
    // console.log("Discard");
    resetSelectedPriceLevel();
    setCustomersUnsavedTags([...customersSavedTags]);

    setCanPlaceOwnDiscountedOrders(
      customersSavedTags.includes(selfServeTagValue)
    );
    setCanPayLater(customersSavedTags.includes(termsTagValue));
  });

  useEffect(() => {
    contextualSaveBar.subscribe(ContextualSaveBar.Action.SAVE, () => {
      // console.log({ customersUnsavedTags });
      updateCustomer();
    });
  }, [contextualSaveBar, customersUnsavedTags]);

  // Remove save bar when leaving the page
  // useEffect(() => {
  //   return () => {
  //     contextualSaveBar.dispatch(ContextualSaveBar.Action.HIDE);
  //     contextualSaveBar.unsubscribe();
  //   };
  // }, [contextualSaveBar]);

  // const contextBar = hasUnappliedChange ? (
  //   <ContextualSaveBar
  //     message="Unsaved product"
  //     saveAction={{
  //       onAction: () => {

  //       },
  //       loading: false,
  //       disabled: false,
  //     }}
  //     discardAction={{
  //       onAction: () => {

  //       },
  //     }}
  //   />
  // ) : null;
  return (
    <Page
      title={`${pageTitle}${
        data?.customer ? " " + data.customer.displayName : ""
      }`}
      breadcrumbs={[{ content: "Draft", onAction: redirectToCustomer }]}
    >
      <TitleBar title={pageTitle} />
      {/* {contextBar} */}
      <Layout>
        {data && !data.customer ? (
          <Layout.Section>
            <Card sectioned>
              <EmptyState
                heading="Requested customer not found"
                image="https://cdn.shopify.com/shopifycloud/web/assets/v1/08f1b23a19257042c52beca099d900b0.svg"
                // image={CircleAlertMajor}
              >
                <p>
                  The customer profile might have been deleted or moved. Check
                  the web address and try again, or try searching from the
                  Customers page.
                </p>
              </EmptyState>
            </Card>
          </Layout.Section>
        ) : (
          <>
            <Layout.AnnotatedSection
              title="Custom pricing"
              description="Determines line item price for processed orders."
            >
              <Card sectioned>
                <FormLayout>
                  <Select
                    label="Price level"
                    options={priceOptions}
                    onChange={handlePriceLevelSelectChange}
                    value={selectedPriceTag}
                  />
                </FormLayout>
              </Card>
            </Layout.AnnotatedSection>

            <Layout.AnnotatedSection
              title="Self serve options"
              description="What can the customer do when placing an order themselves via the web."
            >
              <Card>
                <Card.Section subdued={!canSelectSelfServeOptions}>
                  <Stack vertical>
                    <RadioButton
                      label="Custom pricing disabled"
                      helpText="Customer will only get discounts on invoices sent by staff."
                      checked={!canPlaceOwnDiscountedOrders}
                      id="self-serve-disabled"
                      value="false"
                      name="selfserve"
                      disabled={!canSelectSelfServeOptions}
                      onChange={handleSelfServeChange}
                    />
                    <RadioButton
                      label="Custom pricing enabled"
                      helpText="Customer can place orders with discounts while logged into this account at the above price level."
                      value="true"
                      id="self-serve-enabled"
                      name="selfserve"
                      checked={canPlaceOwnDiscountedOrders}
                      disabled={!canSelectSelfServeOptions}
                      onChange={handleSelfServeChange}
                    />
                  </Stack>
                </Card.Section>
                <Card.Section subdued={!canSelectTermOptions}>
                  <Stack vertical>
                    <RadioButton
                      label="Payment terms disabled"
                      helpText="Customer must pay at checkout."
                      checked={!canPayLayer}
                      value="false"
                      id="terms-disabled"
                      disabled={!canSelectTermOptions}
                      name="terms"
                      onChange={handlePaymentTermsChange}
                    />
                    <RadioButton
                      label="Payment terms enabled"
                      helpText="Customer pays on terms. Orders can be fulfilled without payment."
                      value="true"
                      id="terms-enabled"
                      name="terms"
                      checked={canPayLayer}
                      disabled={!canSelectTermOptions}
                      onChange={handlePaymentTermsChange}
                    />
                  </Stack>
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
            {/*
              
            <Layout.AnnotatedSection
              title="Debug"
              description="Pay no attention to that {...wtf} behind the curtain! 🐶"
            >
              <Card subdued={true}>
                <Card.Section title="Unsaved tag set">
                  <Stack spacing="extraTight">
                    {customersUnsavedTags.map((tag, id) => {
                      return <Tag key={id}>{tag}</Tag>;
                    })}
                  </Stack>
                </Card.Section>
                <Card.Section title="Saved tag set">
                  <Stack spacing="extraTight">
                    {customersSavedTags.map((tag, id) => {
                      return <Tag key={id}>{tag}</Tag>;
                    })}
                  </Stack>
                </Card.Section>
                <Card.Section title="json">
                  <TextStyle variation="code">
                    <pre>
                      {JSON.stringify(
                        {
                          saveErr: saveError || "none",
                          saveData,
                          id,
                          pageContext,
                          pageData,
                          data,
                          loading,
                          err: error || "none",
                          location,
                        },
                        null,
                        "  "
                      )}
                    </pre>
                  </TextStyle>
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
               */}
          </>
        )}
      </Layout>
    </Page>
  );
};

export default CustomerSettings;
